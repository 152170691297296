import { Component, OnInit, Injectable } from '@angular/core';

import { AuthService } from './auth.service'

declare const FB: any;
declare const AWS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'website-quicksite';
  //loggedIn = true;

  constructor(private authService: AuthService){}

  ngOnInit(){
    this.authService.subscribe();
    //this.authService.S3ListObject();
  }
}
