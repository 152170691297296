import { Injectable } from '@angular/core';

declare const FB: any;
declare const AWS: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService{

  public loggedIn = false;

  constructor() {
    FB.init({
      appId            : '331941470795547',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v3.2'
    });

    FB.Event.subscribe('auth.statusChange', (response)=>{
      console.log(response);
      if(response.authResponse) {
        AWS.config.update({
          region: 'us-east-1'
        });
    
        // Add the Facebook access token to the Cognito credentials login map.
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'us-east-1:f7bd2ec3-ea9b-44b3-ac6e-824dec7b8dfa',
          Logins: {
            'graph.facebook.com': response.authResponse.accessToken
          }
        });

        this.S3ListObject();
      }
    });
   }

  S3ListObject() {
    var s3 = new AWS.S3();
    var params = {
      Bucket: "test-bucket1995"
    };
    s3.listObjects(params, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else     console.log(data);           // successful response
    });
  }

  subscribe() {
    
  }
}
